import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HomeServiceMember = ({ item }) => (
  <ItemWrapper>
    <Member to={`/team/${item.slug}`}>
      <img src={item.acfTeam.image.sourceUrl} alt={item.title} />
    </Member>
  </ItemWrapper>
)
export default HomeServiceMember

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ItemWrapper = styled.div`
  height: 85px;
  width: 85px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border: 2px solid ${(props) => props.theme.gold};
  position: relative;
  transition: all 0.15s ease;
  :hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`
const Member = styled(Link)`
  height: 85px;
  width: 85px;
  img {
    max-width: 200% !important;
    margin: 0px;
    position: absolute;
    left: -40px;
    top: 0;
  }
`
